import { useState, useEffect } from "react";
// import Joi from "joi-browser";
import axios from "axios";

function Search() {
  const [title, setTitle] = useState();
  const [qtyLimit, setQtyLimit] = useState();

  // const schema = {
  //   search: Joi.string().required().label("Search"),
  // };

  async function browse(q) {
    const res = await axios.get(
      `/api/ebay/buy/browse/item_summary/search?q=${q}`
    );
    setTitle(
      res.data.total === 0 ? "No results" : res.data.itemSummaries[0].title
    );
  }

  useEffect(() => {
    async function getQtyLimit() {
      const res = await axios.get(`/api/ebay/account/privilege`);
      setQtyLimit(res.data.sellingLimit.quantity);
    }
    getQtyLimit();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const query = e.target[0].value;
    query && browse(query);
  };

  return (
    <div className="m-3">
      <div className="m-3">
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <label htmlFor="query" className="form-label">
              Search items in eBay
            </label>
            <input type="text" className="form-control" id="query" />
          </div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
        {title}
      </div>
      <div className="m-3">{qtyLimit}</div>
    </div>
  );
}

export default Search;
